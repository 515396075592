.employee-node {
    display: flex;
    align-items: center;
    padding: 5px;
}

.employee-label {
    font-size: 14px;
    margin-right: 10px;
}

.employee-icons {
    display: flex;
    gap: 5px;
}

.icon-btn {
    cursor: pointer;
    color: #007bff;
    font-size: 16px;
    padding: 0 5px;
}

.icon-btn.view {
    color: #17a2b8;
}

.icon-btn.edit {
    color: #28a745;
}

.icon-btn.delete {
    color: #dc3545;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-list {
    display: flex;
    list-style: none;
    padding: 0;
}

.page-item {
    margin: 0 5px;
}

.page-link {
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #fff;
}

.page-item.active .page-link {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.page-link:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

