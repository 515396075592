
.node__root > circle {
    fill: red;
}

.node__branch > circle {
    fill: yellow;
}

.node__leaf > circle {
    fill: green;
    /* Let's also make the radius of leaf nodes larger */
    r: 40;
}
